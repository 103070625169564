import axios from "axios"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import ConsultarPedidoBtn from "../components/ConsultarPedidoBtn"
import EcomFormCep from "../components/EcomFormCep"
import EcomMenu from "../components/EcomMenu"
import Footer from "../components/Footer"
import JaEClienteBtn from "../components/JaEClienteBtn"
import ConhecaADiferenca from "../components/Modal/Modals/ConhecaADiferenca"
import EsteESeuEndereco from "../components/Modal/Modals/EsteESeuEndereco"

import { GeolocationResponse } from "../types/GeolocationResponse"

import jsonwebtoken from "jsonwebtoken"
import { useFeatureFlag } from "../hooks"
import useGeneralRules from "../hooks/useGeneralRules"
import ModalService from "../services/ModalService"
import { makeQueryString } from "../utils"
import styles from "./styles.module.scss"

interface RedirectResponse {
  shouldRedirect: boolean
  nextUrl: string | undefined
}

export default function Cobertura() {
  const router = useRouter()
  const [isMobile, setIsMobile] = useState(true)
  const [isTablet, setIsTablet] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [redirect, setRedirect] = useState(true)
  const { storyblok, response, getGeneralRules } = useGeneralRules()
  const { getFeatureFlag } = useFeatureFlag()

  const resolveRedirect = (tvId: string): RedirectResponse => {
    const data = storyblok.porteira
    const appIds = data.app_tv_ids.split(/\s*,\s*/)
    const boxIds = data.box_tv_ids.split(/\s*,\s*/)
    const tvIdArray = tvId.split(",")
    const matchedAppItem = appIds.find((item) => tvIdArray.includes(item));

    if (matchedAppItem) { 
      window.location.href = `${process.env.redirectAppSingle}?tvId=${tvId}`      
    } else if (boxIds.some((item) => tvIdArray.includes(item))) {
      return { shouldRedirect: true, nextUrl: "/box-tv" }
    } else {
      return { shouldRedirect: true, nextUrl: "/" }
    }
  }

  const getUserLocation = (): void => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const coordinates = `${position.coords.longitude},${position.coords.latitude}`
      const response = await axios.get(
        `/cobertura/api/geolocation/${coordinates}`
      )
      const data: GeolocationResponse = response.data

      if (data.zipCode && data.number) {
        ModalService.open({
          component: EsteESeuEndereco,
          componentProps: {
            geolocationResponse: data
          },
          size: "sm",
          showHeader: true,
          title: "Este é seu endereço de instalação?",
          taguear: true,
          tags: {
            abertura: {
              event: "event",
              eventCategory: "planos-claro-res:cep:modal-este-e-seu-endereco",
              eventAction: "modal:abriu",
              eventLabel: "geolocalizacao"
            },
            fechamento: {
              event: "event",
              eventCategory: "planos-claro-res:cep:modal-este-e-seu-endereco",
              eventAction: "modal:fechou",
              eventLabel: "geolocalizacao"
            }
          }
        })
      }
    })
  }

  const sendToExclusiveCheckout = async (cep, number, queryParams) => {
    const baseUrl = process.env.prospectUrl
    try {
      const { data } = await axios.get(
        `${baseUrl}/cobertura/api/available-technologies`,
        {
          headers: {
            cep: cep,
            number: number
          }
        }
      )

      const viability = (jsonwebtoken.decode(data) as { data: any }).data

      const isGpon = !!viability.technologies.find(
        (tech) => tech.gpon && tech.name === "Cable"
      )
      const isHFC = !!viability.technologies.find(
        (tech) => !tech.gpon && tech.name === "Cable"
      )
      const currTechnology = isGpon ? "gpon" : isHFC ? "hfc" : "dth"

      if (currTechnology === "dth") throw Error("DTH")

      router.push(`/checkout-exclusivo?${queryParams}`)
    } catch (err) {
      const prospectUrl = process.env.prospectUrl
      window.location.href = `${prospectUrl}/monte-sua-combinacao?cep=${cep}&number=${number}`
    }
  }

  useEffect(() => {
    getGeneralRules()
    getFeatureFlag()
  }, [])

  useEffect(() => {
    if (!router.isReady) return
    if (!storyblok) return
    const query = router.query
    const { tvId, cep, number, affiliateId } = router.query
    const hasCepNumber = cep && number

    const products = Object.keys(query)
      .filter((key) => key.includes("Id"))
      .reduce((acc, key) => {
        acc[key] = query[key]
        return acc
      }, {})

    for (const param in query) {
      sessionStorage.setItem(param, query[param].toString())
    }

    if (hasCepNumber) {
      const prospectUrl = process.env.prospectUrl

      if (
        storyblok?.exclusiveCheckout?.affiliateIds.includes(
          affiliateId as string
        )
      ) {
        const params = {
          affiliateId: affiliateId,
          cep: cep,
          number: number,
          ...products
        }

        const queryParams = makeQueryString(params)
        sendToExclusiveCheckout(cep, number, queryParams)
      } else {
        const redirectUrl = `${prospectUrl}/monte-sua-combinacao?cep=${cep}&number=${number}`
        window.location.href = redirectUrl
      }

      return
    }

    const hasTvId = router.query.tvId ? true : false
    const hasInternetId = router.query.internetId ? true : false
    const hasMobileId = router.query.celularId ? true : false
    const hasFoneId = router.query.telefoneId ? true : false

    if (hasTvId && !hasInternetId && !hasMobileId && !hasFoneId) {
      // IIFE (Immediately Invoked Function Expression)
      ;(async () => {
        try {
          const response = resolveRedirect(tvId as string)

          if (response.shouldRedirect) {
            console.log(response.nextUrl)
            router.push(response.nextUrl)
          }
        } catch (error) {
          console.error("Erro ao obter dados:", error)
        }
      })()

      return
    }

    setRedirect(false)
  }, [router.isReady, router.query, storyblok])

  useEffect(() => {
    const updateMedia = () => {
      setIsMobile(window.innerWidth <= 600)
      setIsTablet(window.innerWidth > 600 && window.innerWidth <= 1024)
      setIsDesktop(window.innerWidth > 1024)
    }
    updateMedia()
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  useEffect(() => {
    if (router.query.geolocation) {
      const isGeolocationTrue =
        router.query.geolocation === "true" ? true : false
      if (isGeolocationTrue) {
        getUserLocation()
      }
    }
  }, [router.query])

  return (
    <>
      {!redirect && (
        <div className={styles.pageWrapper}>
          <header>
            <EcomMenu />
          </header>
          <main id="main-content">
            {isMobile && (
              <div className={`${styles.banner} ${styles.backgroundMobile}`}>
                <div>
                  <h1
                    className={`mdn-Heading mdn-Heading--sm mdn-Heading bannerHeading ${styles.bannerHeading}`}
                  >
                    <strong>Em apenas 3 minutos você leva o melhor da Claro para sua casa!</strong>
                  </h1>
                </div>
              </div>
            )}

            {isTablet && (
              <div className={`${styles.banner} ${styles.backgroundTablet}`}>
                <div>
                  <h1
                    className={`mdn-Heading mdn-Heading--sm mdn-Heading bannerHeading ${styles.bannerHeading}`}
                  >
                    <strong>Em apenas 3 minutos você leva o melhor da Claro para sua casa!</strong>
                  </h1>
                </div>
              </div>
            )}

            {isDesktop && (
              <div className={`${styles.banner} ${styles.backgroundDesktop}`}>
                <div>
                  <h1
                    className={`mdn-Heading mdn-Heading--sm mdn-Heading bannerHeading ${styles.bannerHeading}`}
                  >
                   <strong>Em apenas 3 minutos você leva o melhor da Claro para sua casa!</strong>
                  </h1>
                </div>
              </div>
            )}

            <div className={styles.content}>
              <div className={`mdn-Row ${styles.container}`}>
                <div className="mdn-Col-xs-12 mdn-Col-lg-6">
                  <p className={`mdn-Text mdn-Text--body ${styles.bodyText}`}>
                    <span className={`${styles.textBold}`}>
                      Digite o CEP e o número do local desejado
                    </span>{" "}
                    para consultar a cobertura da sua região e visualizar as
                    melhores ofertas.
                  </p>
                  <EcomFormCep />
                </div>
                <div className={`mdn-Col-xs-12 mdn-Col-lg-6 ${styles.ctaArea}`}>
                  {/* <ConsultarPedidoBtn
                    caption={
                      isDesktop
                        ? "Consulte seu pedido online de Banda Larga, TV ou Fixo"
                        : "Clique e acompanhe aqui"
                    }
                  >
                    Status do pedido Online
                  </ConsultarPedidoBtn> */}
                  <JaEClienteBtn
                    storyBlok={response}
                    caption={
                      isDesktop
                        ? "Faça login e consulte ofertas exclusivas"
                        : "Consulte ofertas exclusivas"
                    }
                  >
                    É cliente Claro?
                  </JaEClienteBtn>
                </div>
              </div>
            </div>
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      )}
    </>
  )
}
