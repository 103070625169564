import axios from "axios"
import { stnames } from "../../pages/_app";
import JwtService from "../../services/JwtService";

interface FormData {
    name: string;
    email: string;
    phone: string;
    cep: string;
    hasInfoCheckbox: boolean,
    number: string;
}

type ProductType = 'dth' | 'hfc' | 'gpon'

type Tecnology = {
    gpon: boolean
    name: 'Satellite' | 'Cable'
    internet: boolean
    phone: boolean
    tv: boolean
}

type Technologies = Tecnology[]

type ViabilityReturn = { technologies: Technologies, uf: string, cidade: string, regionId: string }

enum ViabilityErrorCodes {
    '500-001' = '500-001'
}

export const sendLead = async (formData: FormData) => {
    const { technologies, cidade, uf, regionId } = await getViability(formData.cep, formData.number) as ViabilityReturn

    const isGpon = !!technologies.find(tech => tech.gpon && tech.name === 'Cable')
    const isHFC = !!technologies.find(tech => !tech.gpon && tech.name === 'Cable')

    const productType: ProductType = isGpon ? 'gpon' : isHFC ? 'hfc' : 'dth'
    const product = await getProducts(productType)
    const additionalInfo = {
        city: cidade,
        state: uf,
        catalog: isGpon || isHFC ? 'net' : 'clarotv',
        regionId,
        mediaChannel: isDesktop() ? 'desktop' : 'mobile'
    }
    const gaSessionId_ = localStorage.getItem('session_id')
    const gaUserId_ = document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
    const ACN: boolean = sessionStorage.getItem(stnames.acn) === 'true'

    const leadObj = mountLeadObject(formData, product, additionalInfo, gaSessionId_, gaUserId_, ACN)

    sessionStorage.setItem('user', JSON.stringify(leadObj.user))
    const payload = await JwtService.objectToPayloadToken(leadObj)
    try {
        const response = await axios.post(`${process.env.baseApiURL}/api/leads`, payload);
        
        if (response.status === 200 || response.status === 201) {
            const leadId = findAttribute(response, "id") 
            const { data } = response.data
            sessionStorage.setItem('leadId', leadId)
            return data
        } else {
            throw new Error("Erro ao enviar o formulário");
        }
    } catch (error) {
        console.error("Erro ao enviar o formulário:", error.response ? error.response.data : error);
        return { error }
    }
};

const findAttribute = (obj, attribute) => {
    if (obj[attribute]) {
        return `${obj[attribute]}`;
    }
    
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            let result = findAttribute(obj[key], attribute);
            if (result) {
                return result;
            }
        }
    }
    
    return null;
};

const getViability = async (cep: string, number: string) => {
    cep = cep.replace(/\D/g, '')
    
    const AVAILABLE_TECHNOLOGIES_RESPONSE_SESSION_NAME = 'available_technologies_response';
    const storage = sessionStorage.getItem(AVAILABLE_TECHNOLOGIES_RESPONSE_SESSION_NAME);

    var jwt = require('jsonwebtoken');

    if (!!storage) {
        const viabilityStorage = jwt.decode(storage)
  
        const cepStorage = viabilityStorage?.data?.cep;
        const numberStorage = viabilityStorage?.data?.number;
  
        if (cepStorage === cep && numberStorage === number) {
          return viabilityStorage?.data
        }
    }

    try {
        if (number === "SN" || number === '') {
            number = '0'
        }

        sessionStorage.setItem('cep', cep)
        sessionStorage.setItem('number', number)

        const viabilityReponse = await axios.get(process.env.availableTechnologies, { headers: {'cep': cep, 'number': number} })
        sessionStorage.setItem(AVAILABLE_TECHNOLOGIES_RESPONSE_SESSION_NAME, viabilityReponse.data);
        const response = jwt.decode(viabilityReponse.data)

        if (response?.timeout_feature_flag) {
            console.error('The FeatureFlag API did not respond because of a timeout')
        }

        if (response?.error_feature_flag) {
            console.error(
                'Sorry, there was a problem with the featureFlag request.',
                {
                    status: response.error_feature_flag?.status,
                    data: response.error_feature_flag?.data
                }
            )
        }
        
        return response.data;

    } catch (error) {

        const { data } = error.response

        const errors: ViabilityErrorCodes[] = [ViabilityErrorCodes['500-001']]

        const errorsReturns = {
            [ViabilityErrorCodes["500-001"]]: {
                technologies: [{
                    gpon: false,
                    name: 'Satellite',
                    internet: false,
                    phone: false,
                    tv: false
                }],
                uf: data.uf,
                cidade: data.cidade,
                regionId: '021'
            }
        }

        if (errors.includes(data.errors.code)) {
            return errorsReturns[data.errors.code]
        }

        console.error("Erro ao enviar o formulário:", error.response ? error.response.data : error);
        return error.response ? error.response.data : error
    }
}

const getProducts = async (id: ProductType) => {
    try {
        const response = await axios.get(`https://api.storyblok.com/v2/cdn/stories/residencial/prospect/cro-form-version/produto?version=draft&token=PP1FL5OznEAZUdDziBYDKwtt&cv=1698345526`);

        const { story } = response.data
        const products = story.content.jsonProduto
        const parsed = JSON.parse(products) as { hfc: any, gpon: any, dth: any }

        return parsed[id]
    } catch (error) {
        error
        console.error("Erro ao consultar o storyblok:", error.response ? error.response.data : error);
        return error.response ? error.response.data : error
    }
}

const mountLeadObject = (formData: FormData, product: any, additionalInfo: any, gaSessionId_: string, gaUserId_: string, ACN: boolean) => {
    const isCellphone = formData.phone.substr(5).replace(/[, -]+/g, "").trim().length === 9
    const croFormVersionStorage = sessionStorage.getItem('cro-form-version');
    const originId_ = croFormVersionStorage === 'v1' ? 'cro_form_version_v1' : croFormVersionStorage === 'v2' ? 'cro_form_version_v2' : ''

    let leadObj: any = {
        user: {
            name: formData.name,
            email: formData.email,
            other: {
                WhatsApp: formData.hasInfoCheckbox,
                gaSessionId: !!gaSessionId_ ? gaSessionId_ : '',
                clientId: !!gaUserId_ ? gaUserId_ : ''
            },
            phone: {
                area: formData.phone.substr(0, 2),
                number: formData.phone.substr(2).replace(/[, -]+/g, "").trim()
            },
            ...(isCellphone && {
                additionalPhone: {
                    area: formData.phone.substr(0, 2),
                    number: formData.phone.substr(2).replace(/[, -]+/g, "").trim()
                },
            })
        },
        catalog: additionalInfo.catalog,
        originId: originId_,
        mediaChannel: additionalInfo.mediaChannel,
        salesChannel: "net",
        products: [product],
        regionId: additionalInfo.regionId,
        payment: {},
        schedule: [],
        installationAddress: {
            number: formData.number === '' ? 0 : formData.number,
            city: additionalInfo.city,
            state: additionalInfo.state,
            type: "R",
            reference: "",
            zipcode: formData.cep.replace(/[, -]+/g, "").trim(),
        },
        installationFee: 0
    }

    if(ACN){
        leadObj.customerOrigin = "base-movel"
    }
    return leadObj
}

const isDesktop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("mobile") === -1;
}
