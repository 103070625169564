import * as jose from "node-jose";
class JwtService {
    constructor() { return }

    async objectToSignedJwt(object: any) {
        const keyStore = jose.JWK.createKeyStore()
        const key = await keyStore.add(process.env.privateJwt, 'pem')

        const token = await jose.JWS.createSign(
            {
                format: "compact",
                fields: {
                    alg: "RS256"
                }
            }, 
            key
        )
        .update(JSON.stringify(object), "utf8")
        .final();

        return token
    }

    async objectToPayloadToken(object: any) {
        const token = await this.objectToSignedJwt(object)
        return {
            token: token
        }
    }
}

export default new JwtService()
